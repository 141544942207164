<template>
  <footer>
    <div id="userKey">
      
    </div>
    <ul>
      <li><small>DEVELOPED WITH PASSIONS by <a href="https://a3m.pl" target="blank">Agencja A3M</a></small></li>
      <li>© Copyright <a href="https://inform.pl/" target="blank"><img src="../assets/inform.svg" alt="INFORM’1" /></a> {{currentYear}}</li>
    </ul>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    }
  },
}
</script>