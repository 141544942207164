<template>
  <aside v-if="showMenu" :class="menuShow ? 'show' : 'hidden'">
    <nav>
      <div class="closer">
        <i class="svg svg-close" @click="hambMenu()" />
      </div>
      <ul>
        <li v-for="menu in filteredMenu(0)" 
            v-bind:key="menu.id">
            <div v-if="menu.path.length > 0 ? true : false">
              <router-link class="nav" 
                  v-bind:to="menu.path" 
                  @click="toggleNav(menu.id, menu.parent)">
                <i class="svg" :class="menu.icon" /> <span :class="{blink: menu.blink}">{{ menu.title }} </span>
              </router-link>
            </div>
            <div v-else class="nav"
                :class="{open : menu.isShow}"
                @click="toggleNav(menu.id, menu.parent)">
              <i class="svg" :class="menu.icon" /> {{ menu.title }}
            </div>
            <div>
              <ul>
                <li v-for="submenu in filteredMenu(menu.id)" 
                    v-bind:key="submenu.id">
                  <router-link class="nav" 
                    v-bind:to="submenu.path" 
                    @click="toggleNav(submenu.id, submenu.parent)">
                    {{ submenu.title }} 
                  </router-link>
                </li>
              </ul>
            </div>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script>
import AppService from '@/services/app.service';
export default {
  // props: ['stat'],
  data() {
    return {
      settings: JSON.parse(localStorage.getItem('settings')),
      menus: [
        { id: 1,    title: 'Start',         path: '/',          icon: 'svg-dashboard',    parent: 0,  isShow: 1,  active: 1, blink: 0},
        { id: 2,    title: 'Wykaz opłat',   path: '',           icon: 'svg-fees',         parent: 0,  isShow: 0,  active: 0, blink: 0},
        { id: 3,    title: 'Rozliczenia',   path: '',           icon: 'svg-settlements',  parent: 0,  isShow: 0,  active: 1, blink: 0},
        { id: 4,    title: 'Saldo',         path: '',           icon: 'svg-balance',      parent: 0,  isShow: 0,  active: 0, blink: 0},
        { id: 5,    title: 'Dokumenty',     path: '',           icon: 'svg-files',        parent: 0,  isShow: 0,  active: 1, blink: 0},
        { id: 6,    title: 'Profil',        path: '/profile',   icon: 'svg-profile',      parent: 0,  isShow: 1,  active: 1, blink: 0},
        { id: 7,    title: 'Kontakt',       path: '/contact',   icon: 'svg-contact',      parent: 0,  isShow: 1,  active: 1, blink: 0},
        { id: 8,    title: 'Aktualności',   path: '/news/1',    icon: 'svg-news',         parent: 0,  isShow: 1,  active: 1, blink: 0}, /* wyłączona */
        { id: 9,    title: 'Głosowanie',    path: '/vote',      icon: 'svg-vote',         parent: 0,  isShow: 1,  active: 1, blink: 0},/* wyłączona */
        { id: 10,   title: 'Regulamin',     path: '/statute',   icon: 'svg-statute',      parent: 0,  isShow: 1,  active: 1, blink: 0},
      ],
      showMenu: false
    }
  },

  watch: {
    '$route': 'getRoute',
  },

  methods: {

    hambMenu() {
      this.$store.commit("updateMenu", { menu: !this.menuShow});
    },

    filteredMenu(val) {
      // console.log('val', val)
      return this.menus.filter((menu) => (menu.parent == val && menu.active==1))
    },
    
    toggleNav(id, parent) {
      let countId = id - 1
      let countPar = parent - 1
      // console.log('toggleNav2',  countId, countPar)
      this.menus[countId].isShow =! this.menus[countId].isShow;
      if (parent != 0) {
        this.menus[countPar].isShow = true
      }
    },
    getRoute() {
      // console.log(this.menus, this.$route.fullPath)
      this.menus.forEach(element => {
        if (element.path == this.$route.fullPath) {
          // console.log('3', this.$route.fullPath, element.path)
          this.toggleNav(element.id, element.parent)
        }
      });
    },
    isAdmin(v) {
      if (v.admin == 1) {
        let id =  this.menus.length + 1;
        this.menus.push({ 
            id: id,
            title: 'Funkcje administracyjne', 
            path: '', 
            icon: 'svg-settings', 
            parent: 0, 
            isShow: 0,
            active: 1,
            blink: 0,
          })
        this.menus.push({ 
            id: id + 1,
            title: 'Kategorie', 
            path: '/settings/category', 
            icon: '', 
            parent: id, 
            isShow: 1,
            active: 1,
            blink: 0,
          })
        this.menus.push({ 
            id: id + 2,
            title: 'Pliki', 
            path: '/settings/files', 
            icon: '', 
            parent: id, 
            isShow: 1,
            active: 1,
            blink: 0,
          })
        this.menus.push({ 
            id: id + 3,
            title: 'Użytkownicy', 
            path: '/settings/users', 
            icon: '', 
            parent: id, 
            isShow: 1,
            active: 1,
            blink: 0,
          })
        this.menus.push({ 
            id: id + 4,
            title: 'Aktualności', 
            path: '/settings/news', 
            icon: '', 
            parent: id, 
            isShow: 1,
            active: 1,
            blink: 0,
          })
        if ('/settings/category' == this.$route.fullPath || '/settings/files' == this.$route.fullPath) {
          // console.log(this.$route.fullPath, this.menus[id].title);
          this.menus[(id-1)].isShow = true;
        }
      }
    },

    genMenu(rel, objkey, path, isShow=1, isActive=1) {
      // console.log('xx', this.menus)
      this.settings[objkey].forEach(element => {
        let id =  this.menus.length + 1;
        if (path + '/' + element.id == this.$route.fullPath) {
          this.menus[(rel-1)].isShow = true;
        } 
        var c = { 
          id: id,
          title: element.nazwa, 
          path: path + '/' + element.id, 
          icon: '', 
          parent: rel, 
          isShow: isShow,
          active: isActive,
          blink: element.blink
        }
        // console.log(c)
      this.menus.push(c)
      });
    },

    getCategoryFile(rel, path) {
      AppService.getCategory().then (
        (response) => {
          // console.log(response.data)
          response.data.forEach(element => {
            let id =  this.menus.length + 1;
            var c = { 
              id: id,
              title: element.nazwa, 
              path: path + '/' + element.catid, 
              icon: '', 
              parent: rel, 
              isShow: 1,
              active: 1,
              blink: element.blink
            }
            // console.log(c)
          this.menus.push(c)
          })
          this.getRoute()
        },
      )
      // console.log( this.menus)
    },

    setSettings() {
      
      this.isAdmin(this.settings)
      var mod = this.settings.modules
      
      this.genMenu(2, 'posesje', '/fees', 0, mod.modNaliczenie);
      this.menus[1].title = this.settings.menu.mnuWymiar
      this.menus[1].active = mod.modNaliczenie

      this.genMenu(3, 'posesje', '/settlements', 0, mod.modRozliczenie);
      this.menus[2].title = this.settings.menu.mnuRozliczenie
      this.menus[2].active = mod.modRozliczenie

      this.menus[3].active = mod.modFinanse
      this.genMenu(4, 'posesje', '/balance', 0, mod.modFinanse);
      this.menus[3].title = this.settings.menu.mnuSaldo

      this.genMenu(5, 'firmy', '/documents', 0, mod.modPliki);
      this.menus[4].title = this.settings.menu.mnuPliki
      this.menus[4].active = mod.modPliki
    
      // console.log(mod.modAktualnosci, mod.modFinanse);
      this.menus[7].active = mod.modAktualnosci  // - jak zostanie utworzony szablon 
      this.menus[8].blink = Object.keys(this.settings.surveys.current).length > 0 ? 1 : 0
      this.menus[8].active = mod.modGłosowanie   // - jak zostanie utworzony szablon 
      
    },

  },

  computed: {
    menuShow() {
      return this.$store.state.menu;
    }
  },

  mounted() {
    // this.getCategoryFile(5, '/documents')
  },

  created() {
    if (localStorage.getItem('settings')) {
      this.showMenu = true
      this.setSettings()
      // this.getRoute()
      
    }
  }
}
</script>