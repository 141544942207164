import axios from 'axios';
import config from './config.service';
import authHeader from './auth.header';

// const API_URL = 'https://inform.pl/eczynsze/api/';
// console.log(config.URL);
class AppService {

  getFees(id) {
    var nowDate = new Date()
    var ts = '?time=' + nowDate.getTime()
    return axios.get(config.URL + 'data/doc/k3/' + id + ts, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }

  getSettlements(id) {
    var nowDate = new Date()
    var ts = '?time=' + nowDate.getTime()
    return axios.get(config.URL + 'data/settlement/k3/' + id + ts, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }

  getBalance(id) {
    var nowDate = new Date()
    var ts = '?time=' + nowDate.getTime()
    return axios.get(config.URL + 'data/finances/k3/' + id + ts, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }

  getNews(id=1) {
    var nowDate = new Date()
    var ts = '?time=' + nowDate.getTime()
    return axios.get(config.URL + 'data/news/'+ id + ts, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }

  getNew() {
    var nowDate = new Date()
    var ts = '?time=' + nowDate.getTime()
    return axios.get(config.URL + 'data/news' + ts, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }

  // getNews(id) {
  //   var nowDate = new Date()
  //   var ts = '?time=' + nowDate.getTime()
  //   return axios.get(config.URL + 'data/news/' + id + ts, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  // }

  getCategory() {
    var nowDate = new Date()
    var ts = '?time=' + nowDate.getTime()
    return axios.get(config.URL + 'data/files' + ts, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }

  getCatFiles(id) {
    var nowDate = new Date()
    var ts = '?time=' + nowDate.getTime()
    return axios.get(config.URL + 'data/files/' + id + ts, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }

  getFile(id) {
    var nowDate = new Date()
    var ts = '?time=' + nowDate.getTime()
    return axios.get(config.URL + 'data/file/' + id + ts, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }
  
  getFileCompany(id) {
    var nowDate = new Date()
    var ts = '?time=' + nowDate.getTime()
    return axios.get(config.URL + 'data/filesk6/' + id + ts, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }

  getStatute() {
    var nowDate = new Date()
    var ts = '?time=' + nowDate.getTime()
    return axios.get(config.URL + 'config/regulamin' + ts);
  }

  postProfileNewPass(data) {
    var nowDate = new Date()
    return axios.post(config.URL + 'user/changepass', data, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }

  postContactForm(data) {
    var nowDate = new Date()
    return axios.post(config.URL + 'user/contact', data, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }



/* Vote */

  getResultVote(id) {
    return axios.get(config.URL + 'data/surveyresult/' + id, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false });
  }

  getFileVote(id) {
    return axios.get(config.URL + 'data/survey/file/' + id, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false });
  }

  getCurrentVote(id) {
    return axios.get(config.URL + 'data/survey/' + id, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false });
  }

  postVote(id, data) {
    return axios.post(config.URL + 'data/survey/' + id, data, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false });
  }






// ADMIN
  getAdminCategory() {
    var nowDate = new Date()
    var ts = '?time=' + nowDate.getTime()
    return axios.get(config.URL + 'admin/filecat' + ts, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }
  putAdminCategory(data) {
    var nowDate = new Date()
    return axios.put(config.URL + 'admin/filecat', data, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }
  deleteAdminCategory(id) {
    var nowDate = new Date()
    return axios.delete(config.URL + 'admin/filecat/' + id, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }
  postAdminCategoryOrd(data) {
    var nowDate = new Date()
    return axios.post(config.URL + 'admin/setorder/filecat', data, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }



  getAdminFiles() {
    var nowDate = new Date()
    var ts = '?time=' + nowDate.getTime()
    return axios.get(config.URL + 'admin/file' + ts, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }
  putAdminFiles(data) {
    var nowDate = new Date()
    return axios.put(config.URL + 'admin/file', data, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }
  postAdminFiles(data) {
    var nowDate = new Date()
    return axios.post(config.URL + 'admin/file', data, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }
  deleteAdminFiles(id) {
    var nowDate = new Date()
    return axios.delete(config.URL + 'admin/file/' + id, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }
  postAdminFilesOrd(data) {
    var nowDate = new Date()
    return axios.post(config.URL + 'admin/setorder/file', data, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }

  getAdminCompanies() {
    var nowDate = new Date()
    var ts = '?time=' + nowDate.getTime()
    return axios.get(config.URL + 'admin/k6list' + ts, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }


  getAdminUsers() {
    var nowDate = new Date()
    var ts = '?time=' + nowDate.getTime()
    return axios.get(config.URL + 'admin/users' + ts, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }
  postAdminActivate(data) {
    var nowDate = new Date()
    return axios.post(config.URL + 'admin/users/activate', data, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }
  postAdminResetpass(data) {
    var nowDate = new Date()
    return axios.post(config.URL + 'admin/users/resetpass', data, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }
  postAdminSetk1(data) {
    var nowDate = new Date()
    var ts = '?time=' + nowDate.getTime()
    return axios.post(config.URL + 'admin/users/setk1'+ts, data, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }



  postSendActivateMail(data) {
    var nowDate = new Date()
    return axios.post(config.URL + 'users/sendactivateemail', data, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }




  getAdminNews() {
    var nowDate = new Date()
    var ts = '?time=' + nowDate.getTime()
    return axios.get(config.URL + 'admin/news' + ts, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }
  getAdminNew(id) {
    var nowDate = new Date()
    var ts = '?time=' + nowDate.getTime()
    return axios.get(config.URL + 'admin/news/' + id + ts, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }
  deleteAdminNew(id) {
    var nowDate = new Date()
    return axios.delete(config.URL + 'admin/news/' + id, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }
  postAdminNew(data) {
    var nowDate = new Date()
    return axios.post(config.URL + 'admin/news', data, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }
  putAdminNew(data) {
    var nowDate = new Date()
    return axios.put(config.URL + 'admin/news', data, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }

  getK6list() {
    var nowDate = new Date()
    var ts = '?time=' + nowDate.getTime()
    return axios.get(config.URL + 'admin/k6list' + ts, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }

  getUserProfileRefresh() {
    var nowDate = new Date()
    var ts = '?time=' + nowDate.getTime()
    return axios.get(config.URL + 'user/profile' + ts, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
  }



}

export default new AppService();