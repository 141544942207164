import { createStore } from 'vuex'
import { auth } from "./auth.module";

export default createStore({
  state: {
    id1: 0,
    id3: 0,
    id6: 0,
    firmy: {},
    posesje: {},
    update: 0,
    menu: false,
  },
  mutations: {
    updateState(state, data) {
      // console.log(data);
      state.id1 = data.id1
      state.id3 = data.id3
      state.id6 = data.id6
      state.firmy = data.firmy
      state.posesje = data.posesje
      state.posesje = 1
    },

    updateMenu(state, data) {
      state.menu = data.menu
    },

  },
  actions: {
  },
  modules: {
    auth,
  },
  getters: {
  }
})
