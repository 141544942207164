import axios from 'axios';
import config from './config.service';
import authHeader from './auth.header';

class UserService {

  getUserBoard() {
    var nowDate = new Date()
    var ts = '?time=' + nowDate.getTime()
    return axios.get(config.URL + 'user/profile'+ ts, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0' });
  }

  getConfig() {
    var nowDate = new Date()
    var ts = '?time=' + nowDate.getTime()
    return axios.get(config.URL + 'config/UI'+ ts, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0' });
  }

  
}

export default new UserService();