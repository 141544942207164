<template>
  <header>
    <div id="hamburger">
      <img src="@/assets/navi.svg" alt="Menu" @click="toggleStatusNavi()" />
    </div>

    <router-link to="/" class="logo">
      <div class="title1" v-if="propHeader.tytul1">{{ propHeader.tytul1 }}</div>
      <div class="title2" v-if="propHeader.tytul2">{{ propHeader.tytul2 }}</div>
    </router-link>

    <ul class="nav ml-md-auto">
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle mr-md-2" data-toggle="dropdown" href="#" role="button" aria-expanded="false"><i class="svg svg-account" /></a>
        <div class="dropdown-menu">
          <div class="user"><b>{{ propHeader.userName }}</b></div>
          <router-link class="dropdown-item" v-bind:to="'/profile'"><i class="svg svg-profile" /> Profil</router-link>
          <router-link class="dropdown-item" v-bind:to="'/contact'"><i class="svg svg-contact" /> Kontakt</router-link>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#" @click.prevent="logOut"><i class="svg svg-logout" /> Wyloguj</a>
        </div>
      </li>
    </ul>

  </header>
</template>

<script>
export default {
  props: ['propHeader'],
  methods: {

    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
      // location.reload();
      // location.href = '/login'
    },

    toggleStatusNavi() {
      var menu = this.$store.state.menu
      this.$store.commit("updateMenu", { menu: !menu});
    }
  },

}
</script>
<style scoped>
.nav-pills  {
  position: relative;
}
.user {
  white-space: initial;
  font-size: .725rem;
  padding: 0 .5rem .5rem;
}
.dropdown-menu {
  right: -35px !important;
  left: auto !important;
  max-width: 5rem;
}
.dropdown-item {
  padding: .425rem .725rem;
}
</style>