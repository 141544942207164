<template>
  <Main v-if="currentUser" />
  <router-view v-if="!currentUser" />
</template>
<script>
import Main from '@/components/Main.vue'
export default {
  data() {
    return {
    }
  },

  components: {
    Main,
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user
    },
  },

}
</script>
