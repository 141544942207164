<template>
  <div id="cookies" class="container" v-if="!cookies">
    <div class="text">Nasza strona internetowa używa plików cookies (tzw. ciasteczka) w celach statystycznych, 
        reklamowych oraz funkcjonalnych. Dzięki nim możemy indywidualnie dostosować stronę do twoich potrzeb. 
        Każdy może zaakceptować pliki cookies albo ma możliwość wyłączenia ich w przeglądarce, dzięki czemu nie 
        będą zbierane żadne informacje. <a href="http://ciasteczka.eu/#jak-wylaczyc-ciasteczka">Dowiedz się więcej 
        jak je wyłączyć</a>.
    </div>
    <a @click="toggleCookies"><img src="../assets/cross.svg" alt="" /></a>
  </div>
</template>

<script>
export default {
  name: 'Cookies',
  data() {
    return {
      cookies: false
    }
  },
  methods: {
    toggleCookies() {
      this.$cookies.set('privacy', true, '3m', '', '')
      this.cookies = this.$cookies.isKey('privacy')
    }
  },
  mounted() {
    this.cookies = this.$cookies.isKey('privacy');
  }
}
</script>

<style>
#cookies {
  position: fixed;
  display: flex;
  flex-wrap: nowrap;
  padding: 0;
  z-index: 2;
  bottom: 1rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: var(--color5);
  justify-content: space-between;
  
}
#cookies .text {
  padding: 1.5rem;
  align-self: center;
  font-size: .825rem;
}
#cookies > a {
  cursor: pointer;
  line-height: 1;
  padding: 3rem;
  background: var(--color3);
  color: white;
  font-weight: bold;
  font-size: 1.225rem;
  text-decoration: none;
  display: flex;
  align-items: center;
}
#cookies > a:hover > * {
  -webkit-transform: rotate(405deg);
}
#cookies > a > * {
  transition-duration: 0.8s;
  transition-property: transform;
}
 </style>