import axios from 'axios';
import config from './config.service';

class AuthService {

  login(user) {
    return axios
      .post(config.URL + 'user/login', {
        user: user.username,
        pass: user.password,
        host: config.HOST,
      })
      .then(response => {
        const now = new Date()
        if (response.data.JWT) {
          localStorage.setItem('user', JSON.stringify(response.data));
          localStorage.setItem('expiry', JSON.stringify(config.TTL));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('settings');
    localStorage.removeItem('user');
    localStorage.removeItem('expiry');
    // axios.get(config.URL + 'logout')
    // .then(response => {
    //     console.log('AuthService.logout Success', response.data)
    //   },
    //   error => {
    //     console.log('AuthService.logout Error', error.data)
    //   });
    // localStorage.removeItem('user');
  }

}

export default new AuthService();