import { createRouter, createWebHashHistory } from 'vue-router'
//import { createRouter, createWebHistory } from 'vue-router'
import Configure from '@/services/config.service';
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    props: true,
  },
  {
    path: '/settlements/:sId',
    name: 'Settlements',
    component: () => import('../views/Settlements.vue'),
    props: true,
  },
  {
    path: '/fees/:fId',
    name: 'Fees',
    component: () => import('../views/Fees.vue'),
    props: true,
  },
  {
    path: '/balance/:bId',
    name: 'Balance',
    component: () => import('../views/Balance.vue'),
    props: true,
  },
  {
    path: '/documents/:cId',
    name: 'Documents',
    component: () => import('../views/Documents.vue'),
    props: true,
  },
  {
    path: '/news/:pid',
    name: 'ClientNews',
    component: () => import('../views/News.vue'),
    props: true,
  },
  {
    path: '/new/:nid',
    name: 'ClientNew',
    component: () => import('../views/New.vue'),
    props: true,
  },
  {
    path: '/vote',
    name: 'ClientVote',
    component: () => import('../views/Votes.vue'),
    props: true,
  },
  {
    path: '/currentvote/:cid',
    name: 'ClientVoteCurrent',
    component: () => import('../views/CurrentVote.vue'),
    props: true,
  },
  {
    path: '/resultvote/:rid',
    name: 'ClientVoteResult',
    component: () => import('../views/ResultVote.vue'),
    props: true,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
    props: true,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
    props: true,
  },
  {
    path: '/statute',
    name: 'Statute',
    component: () => import('../views/Statute.vue'),
    props: true,
  },
  {
    path: '/settings/category',
    name: 'Category Settings',
    component: () => import('../views/settings/Category.vue'),
    props: true,
  },
  {
    path: '/settings/files',
    name: 'Files Settings',
    component: () => import('../views/settings/Files.vue'),
    props: true,
  },
  {
    path: '/settings/users',
    name: 'Users Accout',
    component: () => import('../views/settings/Users.vue'),
    props: true,
  },
  {
    path: '/settings/news',
    name: 'News',
    component: () => import('../views/settings/News.vue'),
    props: true,
  },
  {
    path: '/news/:id',
    name: 'New',
    component: () => import('../views/settings/New.vue'),
    props: true,
  },
  {
    path: '/settings/news/edit/:id',
    name: 'Edit New',
    component: () => import('../views/settings/NewEdit.vue'),
    props: true,
  },
  {
    path: '/settings/news/create',
    name: 'Created New',
    component: () => import('../views/settings/NewCreate.vue'),
    props: true,
  },
  {
    path: "/:catchAll(.*)",
    component: () => import('../views/NotFound.vue'),
  },

  {
    path: "/login",
    name: "Login",
    component: () => import('../views/Login.vue'),
  },
    {
    path: "/forgot/reset",
    name: "Forgot Password",
    component: () => import('../views/forgot/Reset.vue'),
  },
  {
    path: "/forgot/newpass/:userAccount/:activeCode",
    name: "New Password",
    component: () => import('../views/forgot/Password.vue'),
  },
  {
    path: "/activate/:userAccount/:activeCode",
    name: "Activate",
    component: () => import('../views/forgot/Activate.vue'),
  },
  {
    path: "/account",
    name: "Account",
    component: () => import('../views/Account.vue'),
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   component: () => import('../views/Register.vue'),
  // },
]

const router = createRouter({
   history: createWebHashHistory(),
  // history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['Login', 'Forgot Password', 'New Password', 'Activate', 'Statute', 'Dashboard'];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = localStorage.getItem('user');
// console.log('LOGIN', authRequired, loggedIn)

  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
}); 

export default router
