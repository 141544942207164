<template>
  <Header :propHeader="propHeader" v-if="settings.tytul"/>
  <!-- <Hamburger @navi="emitNaviStatus" :stat="naviRespoShow" /> -->
  <div id="content" class="container-fluid" v-if="settings.menu">
    <Menu v-if="settings.menu" :stat="naviRespoShow"/>
    <router-view v-if="settings.posesje" />
    <!-- <Alerts /> -->
  </div>
  <Footer />
  <Cookies />
  <Layer />
</template>

<script>
import Header from '@/components/Header.vue'
// import Hamburger from '@/components/Hamburger.vue'
import Footer from '@/components/Footer.vue'
import Menu from '@/components/Menu.vue'
import Cookies from '@/components/Cookies.vue'
import Layer from '@/components/Layer.vue'
// import Alerts from '@/components/Alerts.vue'
import Configure from '@/services/config.service';
import UserService from '@/services/user.service';
export default {
  
  data() {
    return {
      settings: [],
      navigate: [],
      menuTemplate: [],
      homeTemplate: [],
      naviRespoShow: null,
      path: '',
    }
  },

  methods: {
    emitNaviStatus(status) {
      this.naviRespoShow = status 
      // this.settings = {
      //   menu: status,
      // }
    },
  },

  components: {
    Header,
    // Hamburger,
    Menu,
    Footer,
    Cookies,
    Layer,
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user
    },

    logIn() {
      return this.$store.state.auth.status.loggedIn;
    },

    propMenu() {
      return this.settings
    },

    propHeader() {
      var h = {
        tytul1: this.settings.tytul.tytul1 ? this.settings.tytul.tytul1 : 'e-czynsze',
        tytul2: this.settings.tytul.tytul2 ? this.settings.tytul.tytul2 : 'Twoja platforma czynszowa',
        userName: this.settings.fullname
      }
      return h
    }

  },





  created() {
    // clearing session
    if (!localStorage.getItem('expiry')) {
    ///////////  // this.$store.dispatch('auth/logout');
    ///////////  // this.$router.push('/login');
    }

    if (localStorage.getItem('expiry') < Configure.NOW) {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
    
    
    if (this.$store.state.auth.user) {

      UserService.getUserBoard().then (
        (response) => {
          var profile = []
          var config = []
          profile = response.data
          this.$store.commit("updateState", { id1: profile.id1, id3: profile.id3, id6: profile.id6, firmy: profile.firmy, posesje: profile.posesje });
          
          UserService.getConfig().then (
            (response) => {
              config = response.data
              this.settings = Object.assign( {},  config, profile);
              localStorage.setItem('settings', JSON.stringify(this.settings));

            }
          )
        }
      )
    } 
  }

}
</script>
