// const APIURL = 'https://inform.pl/eczynsze/api/';
const now = new Date()

export default {
  U: '',                                          // if DEV:0, data of user adding to loginform
  P: '',                                          // if DEV:0, data of user adding to loginform
  DEV: 1,                                         // DEV: 0 - Developer, DEV:1 - Production
  APPLANG: 'pl',                                  // Lang version, dictionary
  URL: 'https://tbsonline.pl/api/',          // adres url API
  TTL: now.getTime() + 60 * 30 * 3600,            // time expires
  NOW: now.getTime(),
  HOST: 'https://tbsonline.pl/#/'             // application host  'https://inform.pl/#/'
};